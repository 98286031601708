import pick from 'lodash/pick';
import moment from 'moment';
import { addOffer, editOffer, editOrder } from '@api';


const getIncludedHotels = function (days) {
    return [].concat(
        ...days.map(day => day.hotelPlaceholders)
    )
        .filter(placeholder => placeholder.clientOffer.status === 'included')
        .map(placeholder => Object.assign({}, {
            placeholder,
            type: 'hotel',
        }));
};

const getIncludedFerries = function (days) {
    return [].concat(
        ...days.map(day => day.ferryPlaceholders)
    )
        .filter(placeholder => placeholder.clientOffer.status === 'included')
        .map(placeholder => Object.assign({}, {
            placeholder,
            type: 'ferry',
        }));
};

const getIncludedTrains = function (days) {
    return [].concat(
        ...days.map(day => day.trainPlaceholders)
    )
        .filter(placeholder => placeholder.clientOffer.status === 'included')
        .map(placeholder => Object.assign({}, {
            placeholder,
            type: 'train',
        }));
};

const getIncludedServices = function (days) {
    return [].concat(
        ...days.map(day => day.otherPlaceholders)
    )
        .filter(placeholder => placeholder.clientOffer.status === 'included')
        .map(placeholder => Object.assign({}, {
            placeholder,
            type: 'other',
        }));
};

export const getIncluded = function (days) {
    return [...getIncludedHotels(days), ...getIncludedFerries(days), ...getIncludedServices(days), ...getIncludedTrains(days)];
};

export const getHotelPlaceholders = function (days) {
    return [].concat(
        ...days.map(day => day.hotelPlaceholders)
    );
};

export const getFerryPlaceholders = function (days) {
    return [].concat(
        ...days.map(day => day.ferryPlaceholders)
    );
};

export const getTrainPlaceholders = function (days) {
    return [].concat(
        ...days.map(day => day.trainPlaceholders)
    );
};

export const getOtherPlaceholders = function (days) {
    return [].concat(
        ...days.map(day => day.otherPlaceholders)
    );
};

export const getAirlinePlaceholders = function (days) {
    return [].concat(
        ...days.map(day => day.airlinePlaceholders)
    ).map(placeholder => Object.assign({}, {
        offerPriceType: null
    }, placeholder));
};

/*export const getConditions = function (startAt) {
    return Object.assign({}, {
        category: 'Angebotsoption',
        subcategory: 'zum Paketpreis:',
        deadlineDate: new Date().setDate(new Date().getDate() + 21),
        conditionText: 'test'
    })
};*/

export const prepareOffer = function (state) {
    const {
        attachedOrders,
        type,
        attachRouteDescription,
        introTitle,
        introText,
        minimumPersons,
        contact,
        address,
        offerNr
    } = state.offer;
    const conditions = prepareConditions(state);
    const freePlaces = state.offer.freeplaces;
    const bulletPoints = state.offer.bulletPoints;

    const data = {
        order: `/api/orders/${state.order.id}`,
        attachedOrders: attachedOrders.map(order => `/api/orders/${order.id}`),
        type,
        attachRouteDescription,
        introTitle,
        introText,
        minimumPersons,
        contact: contact ? `/api/contacts/${contact.id}` : null,
        address: address ? `/api/addresses/${address.id}` : null,
        conditions,
        freePlaces,
        bulletPoints,
        offerNr
    };

    return data;
};

export const prepareConditions = function (state) {
    const { conditions } = state.offer;

    const conditionKeys = ['category', 'conditionText', 'subcategory', 'deadlineText', 'deadlineDate', 'isVisible', 'id'];

    return conditions.map(condition => {
        const deadlineDate = condition.deadlineDate;

        return Object.assign({}, pick(condition, conditionKeys), {
            deadlineDate: deadlineDate ? `${deadlineDate.toString().substring(0, 10)} 00:00:00` : null
        });
    });
};

export const fillOffer = function (attachedOffer, order) {
    return {
        id: attachedOffer.id,
        type: attachedOffer.type,
        attachRouteDescription: attachedOffer.attachRouteDescription,
        attachedOrders: fillAttachedOrders(attachedOffer, order),
        introTitle: attachedOffer.introTitle,
        introText: attachedOffer.introText,
        minimumPersons: attachedOffer.minimumPersons,
        address: attachedOffer.address,
        conditions: fillConditions(attachedOffer, order),
        freeplaces: fillFreePlaces(attachedOffer),
        bulletPoints: fillBulletPoints(attachedOffer),
        order: attachedOffer.order,
        offerNr: attachedOffer.offerNr
    };
};

export const fillConditions = function (attachedOffer, order) {
    const startAt = moment(order.startAt, 'DD.MM.YYYY');

    return attachedOffer.conditions.map(condition => {
        const days = condition.deadlineDate ? startAt.diff(moment(condition.deadlineDate, 'DD.MM.YYYY'), 'days') : null;
        return Object.assign({}, condition, {
            key: condition.id,
            //type: condition.deadlineText !== '' ? 'text' : 'date',
            type: condition.category === 'Angebotsoption' ? 'date' : 'days',
            days
        });
    });
};

export const fillFreePlaces = function (attachedOffer) {
    return attachedOffer.freePlaces.map(freeplace => Object.assign({}, freeplace, {
        key: freeplace.id,
    }));
};

export const fillBulletPoints = function (attachedOffer) {
    return attachedOffer.bulletPoints.map(bulletPoint => Object.assign({}, bulletPoint, {
        key: bulletPoint.id,
    }));
};

export const fillAttachedOrders = function (attachedOffer, order) {
    return attachedOffer.attachedOrders.map(attachedOrder => (attachedOrder.id ? attachedOrder : attachedOrder === null ? null : order));
};

export const saveOrder = function (state, order, offerID) {
    if (order) {
        if (state.order.id === order.id) {
            editOrder({
                id: order.id,
                data: {
                    mainOffer: `/api/offers/${offerID}`,
                    attachedOffer: `/api/offers/${offerID}`,
                },
                params: '?_groups[]=order_offer_write'
            });

        } else {
            editOrder({
                id: order.id,
                data: {
                    mainOffer: null,
                    attachedOffer: `/api/offers/${offerID}`,
                },
                params: '?_groups[]=order_offer_write'
            });
        }
    }
};

export const saveOffer = function (state, order = null) {
    return new Promise((resolve, reject) => {
        const data = prepareOffer(state);

        if (state.offer.id) {
            editOffer({ id: state.offer.id, data, params: '?_groups[]=order_offer_write' })
                .then(response => {
                    saveOrder(state, order, state.offer.id);
                    resolve(response);
                }).catch(e => {
                    reject('Error while saving offer');
                });
        } else {
            addOffer(data).then(response => {
                saveOrder(state, order, response.data.id);
                resolve(response);
            }).catch(e => {
                reject('Error while saving offer');
            });
        }
    });
};

