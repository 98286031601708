<template>
    <div>
        <loading v-if="isLoading"/>
        <div v-else>
            <div class="level">
                <div></div>
                <div class="buttons has-addons">
                    <button
                        v-bind:class = "['btn-single-toggle',  isDraggableDisabled ? '' : 'is-active']"
                        v-on:click   = "isDraggableDisabled = !isDraggableDisabled"
                        v-bind:title = "isDraggableDisabled ? 'Ziehen aktivieren' : 'Ziehen blockieren'"
                    ><i class="material-icons">lock</i></button>

                    <button
                        v-bind:class = "['btn-single-toggle',  isRouteView ? 'is-active' : '']"
                        style="margin-right: 1px"
                        v-on:click   = "isRouteView = !isRouteView"
                    ><i class="material-icons">place</i></button>
                </div>
            </div>


            <div class="is-relative">
                <draggable
                    v-bind:list="sortedDays"
                    v-on:change="changeOrder"
                    v-bind:options="{ disabled: draggableDisabled || isDraggableDisabled }">

                    <directions
                        v-for="(day, index) in sortedDays" :day="day"
                        :options="{...options, dayDestinations: item.destinations}"
                        v-bind:key="day.id"
                        @updateDay="updateDay"
                        :count-order-concepts="day.countOrderConcepts"
                        @changeRouteView="isRouteView = true"
                        :is-route-view="isRouteView"
                        :is-draggable-disabled="isDraggableDisabled"
                        @deleteDay="deleteDay(day.id)"
                        @openDayForm="openDayConceptForm(day)"
                        :dayCount="index + 1"
                    ></directions>


<!--                    <accordion
                        v-for="(day, index) in sortedDays"
                        v-bind:key="day.id"
                        class="day has-hidden-arrow is-concept"
                        v-bind:class="{'is-empty': !hasDayPlaceholders(day)}"
                        v-bind:isOpen="hasDayPlaceholders(day)"
                        v-bind:isCollapsibe="hasDayPlaceholders(day)"
                    >

                        <template slot="header">
                            <header>
                                <span>Tag {{ index + 1 }} - {{ day.name }}</span>
                                <div class="day-action-buttons">
                                    <simple-menu material icon="more_vert" title="Menu">
                                        <button v-on:click.stop="editDay(day.id)"><i class="material-icons">mode_edit</i> Bearbeiten</button>
                                        <button v-on:click.stop="deleteDay(day.id)"><i class="material-icons">delete</i> Löschen</button>
                                    </simple-menu>
                                </div>
                            </header>
                        </template>

                    <template slot="content">
                        <template v-if="hasDayPlaceholders(day)">
                            <component
                                v-for              = "placeholder in dayPlaceholders(day)"
                                v-bind:key         = "placeholder.placeholderType + '-' + placeholder.id"
                                v-bind:is          = "placeholder.placeholderType + '-view'"
                                v-bind:placeholder = "placeholder"
                                v-bind:isRouteView = "isRouteView"
                                v-bind:options     = "loadedOptions"
                                isConcept
                                v-bind:showDuration= "false"
                            >
                                <simple-menu material icon="more_vert" title="Menu" v-if="placeholder.placeholderType !== 'route'">
                                    <button
                                            v-on:click.stop="openConceptProviderForm({providers: placeholder.providers, dayId: day.id, placeholder: placeholder, id: placeholder.id})"
                                        ><i class="material-icons">add</i> Leistungsträger auswählen</button>

                                        &lt;!&ndash;                                    <button
                                                                                v-on:click.stop="editPlaceholder(placeholder)"
                                                                            ><i class="material-icons">mode_edit</i> Bearbeiten</button>

                                                                            <button
                                                                                class="btn-delete"
                                                                                v-on:click.stop="deletePlaceholder({
                                                                                    key: types[placeholder.placeholderType],
                                                                                    id:  placeholder.id
                                                                                })"
                                                                            ><i class="material-icons">delete</i> Löschen</button>&ndash;&gt;
                                    </simple-menu>
                                </component>
                            </template>
                            <div class="placeholder" v-else>
                                <span class="text-sm">Das Tageskonzept enthält keine weiteren Elemente</span>
                            </div>
                        </template>

                    </accordion>-->
                    <div slot="footer"></div>
                </draggable>

                <div class="loading-container" v-if="draggableDisabled">
                    <loading v-if="draggableDisabled" />
                </div>
            </div>


            <div class="buttons is-fullwidth has-addons" v-if="!showConfigurator">
                <button
                    class="button is-dashed"
                    v-on:click.prevent="showConfigurator = true"
                >
                    <i class="material-icons">library_books</i> Weiteres bestehendes Tageskonzept hinzufügen
                </button>
                <button
                    class="button is-dashed"
                    v-on:click.prevent="addDayConcept"
                >   <i class="material-icons">add_circle</i>
                    Neues Tageskonzept erstellen
                </button>
            </div>

            <concept-provider-form ref="conceptProviderForm" :options="[]"/>



            <day-concept
                v-if="showConfigurator"
                class="is-dashed"
                v-bind:selected="selectedDayIds"
                v-on:cancel="showConfigurator = false"
                v-on:submit="storeDay"
                v-bind:dayCount="item.days.length"
                v-bind:options="loadedOptions"
                v-bind:destinations="item.destinations"
            />

            <day-concept-form formType="modal" :options="loadedOptions.dayConcept" ref="dayConceptForm" @submit="updateDay"/>

        </div>
    </div>

</template>



<script>
import Draggable   from 'vuedraggable';

import ConceptProviderForm from "@orders/concepts/days/ConceptProviderForm";

import Mixin       from './tab';

import Accordion   from '@components/Accordion';
import SimpleMenu  from '@components/SimpleMenu';
import DayConcept  from '@components/configurator/DayConcept';
import Loading     from '@components/Loading';

import AirlineView from '@orders/placeholders/components/views/Airline';
import FerryView   from '@orders/placeholders/components/views/Ferry';
import HotelView   from '@orders/placeholders/components/views/Hotel';
import RouteView   from '@orders/placeholders/components/views/RouteDetail';
import OtherView   from '@orders/placeholders/components/views/Other';
import FreeView    from '@orders/placeholders/components/views/FreeText';
import TrainView   from '@orders/placeholders/components/views/Train';

import DayConceptForm from '@components/CRUD/Form/crudForms/DayConcept.vue'

import ServiceView from '@orders/placeholders/components/views/Other';


import { optionsDayConcept, getAirports, getPorts, getPredefinedRoutesList, getOtherServiceTypes, optionsProvider, getDayConcepts } from '@api';
import {stripHtml} from "@utilities/functions";
import directions from '@orders/concepts/days/Day';

export default {
    mixins: [Mixin],

    data: function () {
        return {
            isLoading: false,
            showConfigurator: false,
            loadedOptions: {
                dayConcept: {},
                airports: [],
                ports: [],
                routes: [],
                serviceTypes: [],

                hotel: {
                    category: [],
                    stars: []
                },
            },

            isRouteView: true,
            isDraggableDisabled: true
        }
    },

    mounted: function () {
        this.isLoading = true;
        Promise.all([
            getDayConcepts({
                'orderConcepts.id': this.item.id,
            }),
            getAirports(),
            getPorts(),
            getPredefinedRoutesList(),
            getOtherServiceTypes(),
            optionsProvider('hotels'),
            optionsDayConcept()
        ]).then(([days, airports, ports, routes, serviceTypes, hotelOptions, dayConceptOptions]) => {
            this.$store.commit('updateData', {
                days: days.data,
            });
            this.isLoading = false;
            this.loadedOptions.airports     = airports.data;
            this.loadedOptions.ports        = ports.data;
            this.loadedOptions.routes       = routes.data;
            this.loadedOptions.serviceTypes = serviceTypes.data;

            this.loadedOptions.dayConcept   = dayConceptOptions.data;
            this.loadedOptions.hotel        = hotelOptions.data;

            this.isOptionsLoaded = true;
        });
    },

    computed: {
        draggableDisabled: function () {
            return !this.isLoaded
        },

        label: function () {
            if (this.item.days.length > 0) {
                return `Routenbeschreibung (${this.item.days.length} Tage)`;

            } else {
                return 'Routenbeschreibung';
            }
        },

        orderOfDays: function() {
            const order = [];
            this.sortedDays.forEach(day => {
                order.push(String(day.id));
            });
            return order;
        },

        placeholders: function () {
            return [];
        },

        selectedDayIds: function () {
            return this.item.days.map(day => day.id);
        },

        sortedDays: function () {
            const self = this;
            if (this.item.orderOfDays.length === 0) {
                return this.item.days;
            }

            const days = this.item && this.item.days || [];

            return days.sort(function (a, b){
                if(self.item.orderOfDays.indexOf(String(a.id)) === -1){
                    return 1;
                }
                if(self.item.orderOfDays.indexOf(String(b.id)) === -1){
                    return -1;
                }

              return self.item.orderOfDays.indexOf(String(a.id)) > self.item.orderOfDays.indexOf(String(b.id)) ? 1 : -1;
            });
        }
    },


    methods: {
        updateDay (data) {
            this.$store.commit('updateData', {
                days: this.item.days.map(day => ({
                    ...day,
                    ...data.id === day.id && {
                        ...data,
                    }
                })),
            });
        },

        storeDay: function ({concept, index}) {
            this.showConfigurator = false;

            const days = JSON.parse(JSON.stringify(this.item.days))
            days.splice(index, 0, concept)

            this.$store.dispatch('storeProperty', {key: 'days', data: days})
                .then(() => {
                    this.notifySuccess('Der Tag wurde erfolgreich gespeichert!')
                    this.$store.dispatch('storeProperty', {key: 'orderOfDays', data: days.map(day => day.id)})
                }, error => {
                    this.notifyError('Der Tag konnte nicht gespeichert werden')
                })
        },

        changeOrder: function ({moved}) {
            let order = this.orderOfDays;

            const temp = order[moved.oldIndex];
            order[moved.oldIndex] = order[moved.newIndex];
            order[moved.newIndex] = temp;

            this.$store.dispatch('storeProperty', { key: 'orderOfDays', data: order})
                .then(() => this.notifySuccess('Die Reihenfolge der Tage wurde erfolgreich gespeichert'),
                error => this.notifyError('Die neue Reihenfolge konnte nicht gespeichert werden'))
        },

        editDay: function (id) {
            window.open(`/concepts/days/${id}`, '_blank');
            //window.location.href = `/concepts/days/${id}`;
        },

        deleteDay: function (id) {
            this.deleteItem('days', { id })
        },

        dayPlaceholders: function (day) {
           const { sortHelper, airlinePlaceholders, hotelPlaceholders, ferryPlaceholders, otherPlaceholders, freeTextItems, routeDetails, trainPlaceholders } = day;


            return [
                ...airlinePlaceholders.map(item => Object.assign({}, item, {
                    placeholderType: `airline`
                })),
                ...hotelPlaceholders.map(item => Object.assign({}, item, {
                    placeholderType: `hotel`,
                    nights: this.item.hotelNights && this.item.hotelNights[item.id] ? this.item.hotelNights[item.id].nights : '',
                })),
                ...ferryPlaceholders.map(item => Object.assign({}, item, {
                    placeholderType: `ferry`
                })),
                ...otherPlaceholders.map(item => Object.assign({}, item, {
                    placeholderType: `other`
                })),
                ...routeDetails.map(item => Object.assign({}, item, {
                    placeholderType: `route`
                })),
                ...freeTextItems.map(item => Object.assign({}, item, {
                    placeholderType: `free`, routeDescription: stripHtml(item.text)
                })),
                ...trainPlaceholders.map(item => Object.assign({}, item, {
                    placeholderType: `train`
                }))

            ].sort((a,b) => {
                return sortHelper.indexOf(`${a.placeholderType}-${a.id}`) < sortHelper.indexOf(`${b.placeholderType}-${b.id}`) ? -1 : 1;
            });
        },

        hasDayPlaceholders: function (day) {
            return this.dayPlaceholders(day).length > 0;
        },

        addDayConcept: function () {
            window.open('/concepts/days?add', '_blank')
        },

        openConceptProviderForm: function (data = {}) {
            this.$refs.conceptProviderForm.open(data);
        },

        openDayConceptForm (data = {}) {
            this.$refs.dayConceptForm.openModal(data)
        }
    },

    components: {
        directions,
        Accordion,
        Draggable,
        ConceptProviderForm,
        DayConcept,
        Loading,
        SimpleMenu,

        // Views
        AirlineView,
        FerryView,
        HotelView,
        RouteView,
        OtherView,
        FreeView,
        TrainView,
        DayConceptForm,
    }
}
</script>
